// http://ionicframework.com/docs/v2/theming/


// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

// ##################### FONT #############################
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$font-path: "./";
@import "./assets/fonts/roboto";
@import "./assets/fonts/noto-sans";

/*
@font-face {
    font-family: 'calibri';
    src: url('../../fonts/gurbaniakhar.ttf.eot');
    src: url('../../fonts/gurbaniakhar.ttf.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/gurbaniakhar.ttf.woff2') format('woff2'),
         url('../../fonts/gurbaniakhar.ttf.woff') format('woff'),
         url('../../fonts/gurbaniakhar.ttf.ttf') format('truetype'),
         url('../../fonts/gurbaniakhar.ttf.svg#gurbaniakharregular') format('svg');
    font-weight: normal;
    font-style: normal;
}*/


$color-base: #337ab7;
$color-danger: #f53d3d;



:root {
	--ion-font-family: "Roboto", "Noto Sans", sans-serif;
	--ion-default-font: "Roboto", "Noto Sans", sans-serif;
	font-family: var(--ion-font-family) !important;
	--ion-color-base: #337ab7;
	--ion-color-danger: #f53d3d;
	--ion-default-background-color: #98bce4;
	--ion-default-background-text-color: black;
}

.default-background {
	--ion-background-color: var(--ion-color-primary);
	--background: var(--ion-color-primary);
	background-color: var(--ion-color-primary);
	background: var(--ion-color-primary);
	--ion-text-color: #ffffff;
}

@media (prefers-color-scheme: dark) {
	:root {
		--ion-default-background-color: #0f427c;
		--ion-default-background-text-color: white;
	}

	.default-background {
		--ion-background-color: #0f427c;
		--background: #0f427c;
		background-color: #0f427c;
		background: #0f427c;
	}

	ion-datetime{
		color: white;
	}
}

// ##################### BAR BUTTONS #############################

ion-back-button {
	color: white !important;
	display: none;
}

ion-toolbar {
	--border-color: transparent;
}

.back-button {
  color: white !important;
}

.back-button-text {
  display: none;
}

.bar-buttons-md[start]{
	-webkit-box-ordinal-group: 3 !important;
	order: 3 !important;
	transform: translateZ(0);
}

.header-md::after{
	background-image: none !important;
}

// ##################### NAVBAR IMGS #############################

.menu-button {

		display: block !important;
		float: right;
		border-radius: 50%;
		background-color: transparent;
		height: 2.5em !important;
		img {
			width: 2.5em !important;
			height: 2.5em !important;
			float: right;
			border-radius: 50%;
		}
}

.logo {

	background-color: transparent;

	img {
			height: 2.5em !important;
	}

}

// ################# AVATAR SIZE OF PROFILE IMAGE ###############

.user-avatar-profile{

		width: 15em;
		height: 15em;
		border-radius: 50%;
}


// ##################### CONTENT #############################

ion-content {


        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;

}

// ##################### WIDTH #############################

ion-col {

	/*

	&[width-0] {
		flex: 0 0 0%;
		max-width: 0%;
	}
	&[width-5] {
		flex: 0 0 5%;
		max-width: 5%;
	}
	&[width-12-5] {
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}
	&[width-15] {
		flex: 0 0 15%;
		max-width: 15%;
	}
	&[width-20] {
		flex: 0 0 20%;
		max-width: 20%;
	}
	&[width-30] {
		flex: 0 0 30%;
		max-width: 30%;
	}
	&[width-35] {
		flex: 0 0 35%;
		max-width: 35%;
	}
	&[width-40] {
		flex: 0 0 40%;
		max-width: 40%;
	}
	&[width-45] {
		flex: 0 0 45%;
		max-width: 45%;
	}
	&[width-60] {
		flex: 0 0 60%;
		max-width: 60%;
	}
	&[width-65] {
		flex: 0 0 65%;
		max-width: 65%;
	}
	&[width-70] {
		flex: 0 0 70%;
		max-width: 70%;
	}
	&[width-95] {
		flex: 0 0 95%;
		max-width: 95%;
	}*/

	&[col-0_25] {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 2.08333%;
		-ms-flex: 0 0 2.08333%;
		flex: 0 0 2.08333%;
		width: 2.08333%;
		max-width: 2.08333%;
	}

	&[col-0_5] {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 4.166666%;
		-ms-flex: 0 0 4.166666%;
		flex: 0 0 4.166666%;
		width: 4.166666%;
		max-width: 4.166666%;
	}

	&[col-1_5] {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 12.49999%;
		-ms-flex: 0 0 12.49999%;
		flex: 0 0 12.49999%;
		width: 12.49999%;
		max-width: 12.49999%;
	}

	&[col-1] {
		padding: 0 !important;
	}
	&[col-2] {
		padding: 0 !important;
	}
	&[col-3] {
		padding: 0 !important;
	}
	&[col-4] {
		padding: 0 !important;
	}
	&[col-5] {
		padding: 0 !important;
	}
	&[col-6] {
		padding: 0 !important;
	}
	&[col-7] {
		padding: 0 !important;
	}
	&[col-8] {
		padding: 0 !important;
	}
	&[col-9] {
		padding: 0 !important;
	}
	&[col-10] {
		padding: 0 !important;
	}
	&[col-11] {
		padding: 0 !important;
	}
	&[col-12] {
		padding: 0 !important;
	}



}

.col{
	padding: 0 !important;
}
// ##################### TOOLBAR #############################
.toolbar-background{
    background-color: var(--ion-color-base);
}

.toolbar-title{
    color: white;
}



.toolbar-title-md {
	padding: 0;
}

// ##################### ITEMS GRID #############################

ion-item ion-grid ion-col{
	padding: 0 0 0 0;
}

// ##################### FOOTER #############################
.footer button {
	background-color: transparent;
	color: white;
	width: 96%;
	margin: 0 !important;
}

.footer .one-button-row {

	width: 100% !important;
}


.footer .active {
		border-top: 2px solid var(--ion-color-base);
		background-color: white;

		button {
			color: var(--ion-color-base);
			width: 100% !important;
		}
	}

.footer button.disable {
		color: gray !important;
		width: 96% !important;
	}

.footer ion-grid{

	padding: 0 0 0 0;
}

.footer ion-col{

	padding: 0 0 0 0;
}

.footer label {
	display: block;
	white-space: nowrap;
	overflow: hidden;
}
// ##################################################

 ion-app.ios [padding], ion-app.ios [padding] .scroll-content,  ion-app.md [padding], ion-app.md [padding] .scroll-content{
    padding: 0 0 0 0 !important;
}


.item-ios.item-block .item-inner, .item-md.item-block .item-inner  {
    padding-right: 0;
}

.item, .item-ios, .item-md {
	padding: 0% 0.5rem 0% 0.5rem !important;
}

ion-grid {
	padding: 0 !important;
}

ion-row {
	padding: 1% 0% 1% 0%;
}

ion-footer ion-toolbar ion-grid ion-row {

	padding: 0 !important;
}

.label-ios,  .label-md{
    margin: 0 !important;
}

.list-ios, .list-md {
    margin:  0 !important;
}

i.btn-text {
	padding-left: 5px;
}

.header-doctitle ion-label {
	overflow: visible;
    white-space: normal;
}


@mixin toast($background-color, $color) {
    .toast-wrapper {
        background-color: $background-color;

    }
    .toast-message {
        color: $color;

    }
    .toast-button {
        color: $color;
    }
}

ion-toast.success {
    @include toast(#d6e9c6, #3c763d);
}

ion-toast.info {
    @include toast(#d9edf7, #31708f);
}

ion-toast.error {
    @include toast(#ebccd1, #a94442);
}

ion-toast.warning{
    @include toast(#fcf8e3, #8a6d3b);
}

ion-footer ion-toolbar{
	padding: 0px !important;
}


.titleArea {
	background-color: var(--ion-color-base);
	border-radius: 1em;
}

.titleText{
	color: white;
	text-align: center;
	padding: 2%;
	white-space: normal;
}

.inputArea {

	.text-input-ios, .text-input-md {
		margin: 0 !important;
		padding: 2%;
		width: 100%;
		border: 1px solid gray;
		border-radius: 1em;
	}
}


ion-list .checkboxList {

		margin: 0 5px 0 5px !important;
		padding: 1% 1% 2% 1%;
		border: 1px solid gray;
		border-radius: 1em;

		ion-item:not(:last-child) {
			border-bottom-width: 0;

		}

		ion-grid.line-divider{
			border-bottom: 0.55px solid #c8c7cc;
		}
	}

.footerRightButton {

	position: fixed;
    bottom: 50px;
    right: 0;
    z-index: 10;
    display: block;
	-webkit-backface-visibility: hidden;

}

.button-md, .searchbar-md .searchbar-input, .list-md .item-input.input-has-focus:last-child, .list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):last-child{
	box-shadow: none;
}



.item-datetime .label-md, .item-select .label-md {
	color: white
}

.text-hidden-overflow {
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
}

.text-hidden-overflowx {
	overflow-x: hidden !important;
	@extend .text-hidden-overflow;
}

.text-hidden-overflowy {
	overflow-y: hidden !important;
	@extend .text-hidden-overflow;
}

.text-hidden-overflowxy {
	overflow: hidden !important;
	@extend .text-hidden-overflow;
}

//toolbal - start
ion-toolbar.edit .toolbar-background {
	background-color: #ECECEC;
}

.footer ion-toolbar.edit button {
	color: $color-base;
}
//toolbal - end

//workflow - start
.workflow-iconstate {
	font-size: 6.5pt;
}

.workflow-notfinalstate {
	color: #FF5555 !important;
}

.workflow-finalstate {
	color: #ABC837;
}
//workflow - end

.clickable:active{
	opacity: 0.2;
}

.marginRight{
	margin-right: 1rem;
}

.marginBottom{
	margin-bottom: 1rem;
}

.no-scroll{
	overflow: hidden;
}

.advanced-search-div {

	overflow: scroll;

	@media only screen and (orientation: portrait) {
		height: 33rem;
	}


	@media only screen and (orientation: landscape) {
		height: 13rem;
	}
}

.advanced-search-button-disable{
	background-color: rgb(196, 221, 243);
	cursor: default;
    pointer-events: none;
}

.advanced-search-cancel-button{
	margin: 0;
}

.advanced-search-cancel-col{
	text-align: center;
}

.no-items-info{
	color: gray;
}

.no-padding{
	padding: 0 !important;
}

.modalChoice{
	text-align: right;
	padding-right: 8px
}

.disabled {
	opacity: 0.5;
}

.disabled-without-transparency {
	cursor: default;
	pointer-events: none;
}


.item-select .label-md{
	color: black;
}

.list-header-md {
	margin-bottom: 0 !important;

}

.item-input .label-md {
	color: black;
}

.toolbar-md ion-title {
	text-align: center !important;
	left: 0;
    top: 0;
    padding: 0 90px 1px;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    pointer-events: none;
}

.navbar-advanced-search{
	background: transparent;
	color: white;

	ion-icon {
		font-size: 3.2rem;
	}
}


.searchbar-ios, .searchbar-ios .searchbar-input {
	background-color: #ECECEC !important;
}

.alert-delete-button{
	@extend .workflow-notfinalstate ;
}

.alert-md .alert-title{
	font-size: 18px;
}

.toolbar-md {
	min-height: 38px !important;
}

.fablisttoolbar {

	margin-left: 0 !important;
	background-color: var(--ion-default-background-color);
	border-radius: 1em;
	padding: 0 1em 0 1em;

	ion-col{
		display: inline-block;
		text-align: center;
		font-size: 0.5em;

		ion-button {
			text-align: center;
			color: var(--ion-default-background-text-color);
			background-color: transparent;
			display: flex;
			align-items: center;
			width: 100%;
		}

		ion-button[text-only] {
			height: 100%;
			display: initial !important;
		}

		ion-button.activated{
			background-color: transparent !important;
			opacity: 0.2;
		}
	}

}

@mixin fab-list-toolbar-width($width) {
    .fablisttoolbar {
		width: $width !important;
	}
}

@media only screen and (min-device-width: 300px) and (max-device-width: 359px) {
    @include fab-list-toolbar-width(400%);
}

@media only screen and (min-device-width: 360px) and (max-device-width: 411px) {
	@include fab-list-toolbar-width(500%);
}

@media only screen and (min-device-width: 412px) and (max-device-width: 767px) {
	@include fab-list-toolbar-width(570%);
}

@media only screen and (min-device-width: 768px) {
	@include fab-list-toolbar-width(1200%);
}

.footer-button-action{
	font-size: 1.7rem;
	width: 100% !important;
	max-width: 100% !important;
	padding: 0 15px;
	text-transform: none !important;
}

.fab-dropup-button{

	--ion-color-primary: #09a268 !important;
	color: #09a268 ;

	.fab-close-icon{
		color: black !important;
	}

	.ion-ios-arrow-dropup{
		color: black
	}

	i {
		color: black
	}
}

.fab-dropup-button.activated{
	background-color: #09a268 !important;
}

.item-options-button{
	height: 100%;
	width: 4em;
	padding-bottom: 2%;
	padding-top: 2%;
}

.slide-options {
	width: auto !important; 
	padding-bottom: 0.3em;
}

.item-options-label{
	font-size: 0.75em;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	width: 100%;
}

ion-spinner[mini] {
	width: 15px;
	height: 15px;
}

.fa-sm {
	font-size: 0.8em;
}

.fa-stack-top-right {
	text-align: right;
	line-height: 0.8em;
	text-shadow: 0 0 1px black;
	top: -0.2em;
	left: 0.2em;

}

.fa-div-stack-top-right{
	text-align: right;
	width: 100%;
	top: -0.5em;
	right: -0.5em;
	position: absolute;

	.fa-circle{
		text-shadow: 0 0 1px black;
	}

	.fa-paperclip {
		font-size: larger;
		bottom: inherit;
	}
}

.action-sheet-md {
	.action-sheet-group:last-child {
		border-top: 0.1em solid #757575;
	}
}

ion-header {
    ion-spinner {
		* {
			stroke: white !important;
		}
	}
}

ion-footer {
	ion-spinner {
		* {
			stroke: white !important;
		}
	}
}

ion-buttons.navigation, ion-buttons.nav-buttons {

	height: 2.5em;

	button {
		background-color: transparent;
		padding: 0.2rem;
		height: 100%;

	ion-icon, ion-spinner {
		margin: -1px 0 0;
		min-width: 18px;
		font-size: 2.5em;
		color: white;
		}
	}

	i {
		color: white;
	}
}

button[no-padding]{
	padding: 0 !important;
}

.item.item-md .checkbox-md {
	margin-right: 20px !important;
}

.danger {
	color: $color-danger;
}
