// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #337ab7;
	--ion-color-primary-rgb: 51,122,183;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #2d6ba1;
	--ion-color-primary-tint: #4787be;

  /** secondary **/
  --ion-color-secondary: #31708f;
	--ion-color-secondary-rgb: 49,112,143;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #2b637e;
	--ion-color-secondary-tint: #467e9a;

  /** info **/
  --ion-color-info: #31708f;
	--ion-color-info-rgb: 49,112,143;
	--ion-color-info-contrast: #ffffff;
	--ion-color-info-contrast-rgb: 255,255,255;
	--ion-color-info-shade: #2b637e;
	--ion-color-info-tint: #467e9a;

  /** tertiary **/
  --ion-color-tertiary: #000099;
	--ion-color-tertiary-rgb: 0,0,153;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #000087;
	--ion-color-tertiary-tint: #1a1aa3;

  /** success **/
  --ion-color-success: #3c763d;
	--ion-color-success-rgb: 60,118,61;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #356836;
	--ion-color-success-tint: #508450;

  /** warning **/
  --ion-color-warning: #8a6d3b;
	--ion-color-warning-rgb: 138,109,59;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255,255,255;
	--ion-color-warning-shade: #796034;
	--ion-color-warning-tint: #967c4f;

  /** danger **/
  --ion-color-danger: #ff5555;
	--ion-color-danger-rgb: 255,85,85;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #e04b4b;
	--ion-color-danger-tint: #ff6666;

  /** dark **/
  --ion-color-dark: #222;
	--ion-color-dark-rgb: 34,34,34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #1e1e1e;
	--ion-color-dark-tint: #383838;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f4f4;
	--ion-color-light-rgb: 244,244,244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d7d7;
	--ion-color-light-tint: #f5f5f5;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #337ab7;
    --ion-color-primary-rgb: 51,122,183;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #2d6ba1;
    --ion-color-primary-tint: #4787be;
  
    /** secondary **/
    --ion-color-secondary: #31708f;
    --ion-color-secondary-rgb: 49,112,143;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #2b637e;
    --ion-color-secondary-tint: #467e9a;
  
    /** info **/
    --ion-color-info: #31708f;
    --ion-color-info-rgb: 49,112,143;
    --ion-color-info-contrast: #ffffff;
    --ion-color-info-contrast-rgb: 255,255,255;
    --ion-color-info-shade: #2b637e;
    --ion-color-info-tint: #467e9a;
  
    /** tertiary **/
    --ion-color-tertiary: #000099;
    --ion-color-tertiary-rgb: 0,0,153;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #000087;
    --ion-color-tertiary-tint: #1a1aa3;
  
    /** success **/
    --ion-color-success: #3c763d;
    --ion-color-success-rgb: 60,118,61;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #356836;
    --ion-color-success-tint: #508450;
  
    /** warning **/
    --ion-color-warning: #8a6d3b;
    --ion-color-warning-rgb: 138,109,59;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255,255,255;
    --ion-color-warning-shade: #796034;
    --ion-color-warning-tint: #967c4f;
  
    /** danger **/
    --ion-color-danger: #ff5555;
    --ion-color-danger-rgb: 255,85,85;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0,0,0;
    --ion-color-danger-shade: #e04b4b;
    --ion-color-danger-tint: #ff6666;

    --ion-color-dark: #f4f4f4;
    --ion-color-dark-rgb: 244,244,244;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d7d7;
    --ion-color-dark-tint: #f5f5f5;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222;
    --ion-color-light-rgb: 34,34,34;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e1e1e;
    --ion-color-light-tint: #383838;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {

    --ion-background-color: #222;
    --ion-background-color-rgb: 34,34,34;
    
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;
  
    --ion-color-step-50: #2d2d2d;
    --ion-color-step-100: #383838;
    --ion-color-step-150: #434343;
    --ion-color-step-200: #4e4e4e;
    --ion-color-step-250: #595959;
    --ion-color-step-300: #646464;
    --ion-color-step-350: #6f6f6f;
    --ion-color-step-400: #7a7a7a;
    --ion-color-step-450: #858585;
    --ion-color-step-500: #919191;
    --ion-color-step-550: #9c9c9c;
    --ion-color-step-600: #a7a7a7;
    --ion-color-step-650: #b2b2b2;
    --ion-color-step-700: #bdbdbd;
    --ion-color-step-750: #c8c8c8;
    --ion-color-step-800: #d3d3d3;
    --ion-color-step-850: #dedede;
    --ion-color-step-900: #e9e9e9;
    --ion-color-step-950: #f4f4f4;

    --ion-item-background: #222;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #222;
    --ion-background-color-rgb: 34,34,34;
    
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;
  
    --ion-color-step-50: #2d2d2d;
    --ion-color-step-100: #383838;
    --ion-color-step-150: #434343;
    --ion-color-step-200: #4e4e4e;
    --ion-color-step-250: #595959;
    --ion-color-step-300: #646464;
    --ion-color-step-350: #6f6f6f;
    --ion-color-step-400: #7a7a7a;
    --ion-color-step-450: #858585;
    --ion-color-step-500: #919191;
    --ion-color-step-550: #9c9c9c;
    --ion-color-step-600: #a7a7a7;
    --ion-color-step-650: #b2b2b2;
    --ion-color-step-700: #bdbdbd;
    --ion-color-step-750: #c8c8c8;
    --ion-color-step-800: #d3d3d3;
    --ion-color-step-850: #dedede;
    --ion-color-step-900: #e9e9e9;
    --ion-color-step-950: #f4f4f4;

    --ion-item-background: #222;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #222;
  }
}

/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/scss/bootstrap';