// Noto Sans Font
// Google
// Apache License, version 2.0
// http://www.apache.org/licenses/LICENSE-2.0.html

$noto-sans-font-path: $font-path !default;

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Noto Sans"), local("Noto-Sans-Regular"), url("#{$noto-sans-font-path}/noto-sans-regular.woff") format("woff"), url("#{$noto-sans-font-path}/noto-sans-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans"), local("Noto-Sans-Regular"), url("#{$noto-sans-font-path}/noto-sans-regular.woff") format("woff"), url("#{$noto-sans-font-path}/noto-sans-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans Bold"), local("Noto-Sans-Bold"), url("#{$noto-sans-font-path}/noto-sans-bold.woff") format("woff"), url("#{$noto-sans-font-path}/noto-sans-bold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans Bold"), local("Noto-Sans-Bold"), url("#{$noto-sans-font-path}/noto-sans-bold.woff") format("woff"), url("#{$noto-sans-font-path}/noto-sans-bold.ttf") format("truetype");
}
